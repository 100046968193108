import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {Subtitle1, H4, BodyText1, styleForColumn} from "./pawesome_style"

export const PawesomeContribution = (props) => {

    return (
        <React.Fragment>
        <Row>
            <Col md={{size:4, offset:1}} style={styleForColumn}>
                <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                    My Contribution
                </H4>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem"}}>
             <Col md={{size:6, offset:5}} style={styleForColumn}>
                <BodyText1>
                    Our team for developing the mobile application consists of 4 people, one designer (Emily), other two developers and me. Due to my extensive experience in both software development and design,  I <strong> managed </strong> the progress of our project and <strong> connected </strong> designers and engineers to work together for realizing our ideas together. I was deeply involved in each step of the design process and development cycle.
                </BodyText1>
            </Col>
        </Row>
        </React.Fragment>
    )
}