import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {Subtitle1, H4, BodyText1, styleForColumn} from "./pawesome_style"
import petOwnerPersona from "../../images/pawesome/pet_owner_persona.png"
import { useInView } from 'react-intersection-observer'
import { opacityChangeAnimation  } from "./pawesome_style"

export const PawesomeDesignGoal = (props) => {
    
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0.2,
        // root:""
    })
    
    return (
        <React.Fragment>
    
        <div ref={ref} style={{...opacityChangeAnimation(inView)}}>
            <Row style={{
                    position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                }}>
                <Col md={{size:4, offset: 4}} style={{...styleForColumn, alignItems:"center"}}>
                    <H4 showDot={false} style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content", textAlign:"center"}}>
                        Design goal
                    </H4>
                </Col>
            </Row>

            <Row style={{marginTop: "1.5rem", position:"relative"}}>
                <Col md={{size:6, offset:3}} style={{...styleForColumn, alignItems:"center"}}>
                    <BodyText1 style={{marginBottom: "1rem", textAlign:"center"}}>
                        {/* Animated Text  */}
                        Designing (and develop) a mobile application to connect <strong> trusted </strong> pet owners within the local community for a better <strong> collaborative </strong> pet sitting experience.
                    </BodyText1>
                </Col>
                {/* <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/> */}
            </Row>
        </div>
        </React.Fragment>
    )
}