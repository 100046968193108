import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {PawesomeH1, Subtitle1, H4, OriginalH4, gutter, BodyText1, paddingOneSide} from "./pawesome_style"

export const PawesomeIntro = (props) => {

    return (
        <Row>
            {/* Need to find a better solution to this look */}
            <Col sm={{size: 10, offset:1}} style={{display:"flex", flexDirection:"column", paddingLeft: `${paddingOneSide}px`, paddingRight: `${paddingOneSide}px`}}>
                <PawesomeH1>
                    Pawesome Companion
                </PawesomeH1>
                
                <Subtitle1 style={{marginTop:"16px", fontWeight: 300}}>
                    Collaborated with Yangxin Xue, Lijie Wei, Mu Bai
                </Subtitle1>

                <Subtitle1 style={{marginTop:"8px", fontWeight: 300}}>
                    Aug. 2019 - Dec. 2019
                </Subtitle1>

                <OriginalH4 style={{marginTop: "2rem", marginBottom:"2rem"}}>
                    The mobile app that connects pet owners in a neighbourhood for a more collaborative pet sitting experience 
                </OriginalH4>

                <iframe width="560" height="315" style={{alignSelf:"center"}} src="https://www.youtube.com/embed/K1_eZhh6Nxs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                
                {/* <img src={rrOverview} style={{marginTop:"60px"}}>

                </img> */}
            </Col>            
        </Row>
    )
}


