import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { Subtitle1, H4, BodyText1, styleForColumn } from './pawesome_style'
import petProblemStatement from '../../images/pawesome/pet_problem_statement.jpg'
import {opacityChangeAnimation} from "./pawesome_style"
import {useInView} from "react-intersection-observer"

export const PawesomeProblemStatement = props => {
  let [ref, inView] = useInView ({
    threshold:0.2
  })
  return (
    <React.Fragment>

      <div ref={ref} style={{...opacityChangeAnimation(inView)}}>
      <Row>
        <Col
          md={{ size: 8, offset: 2 }}
          style={{ ...styleForColumn, alignItems: 'center' }}
        >
          <H4 showDot={false}>
            Problem Statement
          </H4>
        </Col>
      </Row>

      <Row style={{ marginTop: '1.5rem' }}>
        <Col
          md={{ size: 6, offset: 3 }}
          style={{
            ...styleForColumn,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BodyText1 style={{ textAlign: 'center' }}>
            Our problem statement comes from one of my friend’s complaint about
            how hard it is for her to find someone taking care of her cats for
            two weeks.
            <strong>
              {' '}
              We decided to design and develop an mobile app to help those pet
              owners to seek options for taking care of their pets for a short
              period of time.{' '}
            </strong>
          </BodyText1>
        </Col>
      </Row>
      </div>
    </React.Fragment>
  )
}
