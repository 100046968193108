import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import {
  Subtitle1,
  H4,
  H5,
  BodyText1,
  styleForColumn,
  Caption,
  opacityChangeAnimation
} from './pawesome_style'
import highFiScreenOverview from '../../images/pawesome/hifi_screen_overview.png'
import { useInView } from 'react-intersection-observer'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'

import newPostScreen from '../../images/pawesome/hifi/Community/NewPost.png'
import communityFeedScreen from '../../images/pawesome/hifi/Community/CommunityFeed.png'
import referNewMemberScreen from '../../images/pawesome/hifi/Community/ReferNewMember.png'
import createTaskScreen from '../../images/pawesome/hifi/CreateInstructions/create-task.png'
import saveInstructionsScreen from '../../images/pawesome/hifi/CreateInstructions/Saved-template.png'
import petSittingSessionScreen from '../../images/pawesome/hifi/CreateInstructions/Pet-sitting-session.png'

import receivedRequestScreen from '../../images/pawesome/hifi/Discuss/Received-request.png'
import viewRequestChattingScreen from '../../images/pawesome/hifi/Discuss/View-request-chatting.png'
import viewRequestDetailScreen from '../../images/pawesome/hifi/Discuss/View-request-detail.png'

import newRequestFilledScreen from '../../images/pawesome/hifi/FindPetSitter/New-request-filled.png'
import profilePostsScreen from '../../images/pawesome/hifi/FindPetSitter/Profile-posts.png'
import searchResultsMultipleSelectedScreen from '../../images/pawesome/hifi/FindPetSitter/Search-results-multiple-selected.png'

import referFilledScreen from '../../images/pawesome/hifi/Signup/Referal-filled.png'
import landingPageScreen from '../../images/pawesome/hifi/Signup/Landing-screen.png'
import petProfileUnfilled from '../../images/pawesome/hifi/Signup/Pet-profile-unfilled.png'

export const PawesomePrototype = props => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.2,
    // root:""
  })

  const [ref2, inView2] = useInView({
    /* Optional options */
    threshold: 0.2,
    // root:""
  })

  const items = [
    {
      sectionTitle: 'Community feed and user refer',
      screens: [
        {
          src: communityFeedScreen,
          caption: 'Screen for sharing community post',
        },
        { src: newPostScreen, caption: 'Screen for creating a new post' },
        {
          src: referNewMemberScreen,
          caption: 'Screen for referring a new member',
        },
      ],
    },
    {
      sectionTitle: 'User sign up ',
      screens: [
        { src: landingPageScreen, caption: 'Screen for landing page' },
        {
          src: referFilledScreen,
          caption: 'Screen for filling the refer code received',
        },
        {
          src: petProfileUnfilled,
          caption: 'Screen for the unfilled pet profile',
        },
      ],
    },
    {
      sectionTitle: 'Find a Pet pitter',
      screens: [
        { src: newRequestFilledScreen, caption: 'Screen for a new request' },
        {
          src: searchResultsMultipleSelectedScreen,
          caption: 'Screen for the search results of potential pet sitters',
        },
        { src: profilePostsScreen, caption: 'Screen for the user profile' },
      ],
    },
    {
      sectionTitle: 'Users discussing pet sitting request',
      screens: [
        {
          src: receivedRequestScreen,
          caption: 'Screen for receive the request in message tab',
        },
        {
          src: viewRequestChattingScreen,
          caption: 'Screen for view request in the chatting',
        },
        {
          src: viewRequestDetailScreen,
          caption: 'Screen for the detailed page for request',
        },
      ],
    },
    {
      sectionTitle: 'Create instruction',
      screens: [
        {
          src: petSittingSessionScreen,
          caption: 'Screen for a pet sitting session',
        },
        {
          src: createTaskScreen,
          caption: 'Screen for creating tasks from potential pet sitters',
        },
        {
          src: saveInstructionsScreen,
          caption: 'Screen for saved instructions',
        },
      ],
    },
  ]

  const allScreens = items.map(section => (
    <React.Fragment>
      {/* New Row */}
      <Col
        md={{ size: 10, offset: 1 }}
        style={{ ...styleForColumn, marginBottom: '2rem', marginTop: '4rem' }}
      >
        <H5>{section.sectionTitle}</H5>
      </Col>

      <Col md={1} />

      {/* New Row */}
      <Col
        md={{ size: 10, offset: 1 }}
        style={{
          ...styleForColumn,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {section.screens.map(screen => (
          <div
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              width: '23%',
            }}
          >
            <img
              src={screen.src}
              style={{
                marginBottom: '1rem',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'rgba(151,151,151,0.4)',
              }}
            ></img>
            <Caption style={{ textAlign: 'center' }}>{screen.caption}</Caption>
          </div>
        ))}
      </Col>
      <Col md={1}></Col>
    </React.Fragment>
  ))

  return (
    <React.Fragment>

      <div ref={ref} style={{...opacityChangeAnimation(inView)}}>
      <Row
        style={{
          position: 'sticky',
          top: '40px',
          zIndex: 3,
          background: 'transparent',
          // transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
        }}
      >
        <Col md={{ size: 4, offset: 1 }} style={styleForColumn}>
          <H4>
            High-fidelity Prototype
          </H4>
        </Col>
      </Row>

      <Row style={{ marginTop: '1.5rem', position: 'relative' }}>
        <Col md={{ size: 10, offset: 1 }} style={styleForColumn}>
          <BodyText1>
            After the evaluation, Emily and I sat down and spent a long time
            discussing how to face those challenges which targeted directly to
            the goal of our design.
          </BodyText1>

          <H5 style={{ marginTop: '4rem' }}>
            Improve the sense of collaboration through interaction flow
          </H5>

          <BodyText1 style={{ marginTop: '1rem' }}>
            We extracted the feature of “Shared to-do list“ out and created a
            new tab for it so that users have a higher chance to tap into the
            features and realized the use of it. Another pivot decision for
            shared to-do list is to replace to-do list with an instructions
            list, thus making the feature one-way rather than two-way. While the
            change may reduced the “collaboration”, this feature fits in users’
            flow much better, because in most scenario in our user research, pet
            owners rather than sitters give instructions about what to do.
          </BodyText1>

          <H5 style={{ marginTop: '4rem', marginBottom: '1rem' }}>
            Improve the trust among users
          </H5>

          <BodyText1 style={{ marginBottom: '250px' }}>
            We first added more pet related filters in the pet community page to
            explicitly empathize the idea of pet related discussion. I designed
            a community invitation screen to explain our mechanism to invite new
            members to join. Also, we added a feature of explicit recommendation
            in pet sitter search results to help people make decisions.
          </BodyText1>
        </Col>

        <Col md={{ size: 1 }} />
      </Row>
      </div>
      <Row>
        {/* Hifi screens */}
        {allScreens}

        {/* New Row */}
        <Col
          md={{ size: 10, offset: 1 }}
          style={{ ...styleForColumn, marginTop: '3rem' }}
        >
          <H5>Interactive Prototype for both Pet Owner and Sitter</H5>
        </Col>

        <Col md={{ size: 1 }}></Col>
        {/* New Row */}
        <Col
          md={{ size: 5, offset: 1 }}
          style={{
            ...styleForColumn,
            alignItems: 'center',
            marginTop: '-130px',
          }}
        >
          {/* width="442" height="935" */}
          <iframe
            width="442"
            height="935"
            src="//invis.io/3AV9OD9XEUD"
            frameborder="0"
            allowfullscreen
            style={{
              transformOrigin: 'center bottom',
              transform: 'scale(0.75)',
            }}
          ></iframe>
          <Caption style={{ alignSelf: 'center', position: 'relative' }}>
            <strong> Pet Owner </strong> flow
          </Caption>
        </Col>

        <Col
          md={{ size: 5 }}
          style={{
            ...styleForColumn,
            alignItems: 'center',
            marginTop: '-130px',
          }}
        >
          <iframe
            width="442"
            height="935"
            src="//invis.io/JWV9NFK9HMX"
            frameborder="0"
            allowfullscreen
            style={{
              transformOrigin: 'center bottom',
              transform: 'scale(0.75)',
            }}
          ></iframe>
          <Caption style={{ alignSelf: 'center' }}>
            <strong> Pet Sitter </strong> flow
          </Caption>
        </Col>
      </Row>
    </React.Fragment>
  )
}
