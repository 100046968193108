import React, {useState} from 'react'
import {Row, Col, Container} from "reactstrap"
import {Subtitle1, H4, BodyText1, styleForColumn, H5, BodyTextWithLightColor, opacityChangeAnimation} from "./pawesome_style"
import {ZoomableImageWithModal} from "./pawesome_common_components"
import { useInView } from 'react-intersection-observer'
import wireframe from "../../images/pawesome/pawesome_wireframe.jpg"
import codingImage from "../../images/pawesome/coding_image.jpg"
import AppOnThePhone from "../../images/pawesome/app_on_the_phone.jpg"

// import toolWeUse from "../../images/pawesome/tool-we-use.png"
import {Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption} from "reactstrap"

import communityReferralfeedback from "../../images/pawesome/wireframeFeedback/CommunityReferralfeedback.png";
import messageTabDetailFeedback from "../../images/pawesome/wireframeFeedback/MessageTabDetailFeedback.png";
import newInstructionFeedback from "../../images/pawesome/wireframeFeedback/NewInstructionFeedback.png";
import petSittingRequestFeedback from "../../images/pawesome/wireframeFeedback/PetSittingRequestFeedback.png";
import savedInstructionsFeedback from "../../images/pawesome/wireframeFeedback/SavedInstructionsFeedback.png";
import searchResultFeedback from "../../images/pawesome/wireframeFeedback/SearchResultFeedback.png";

export const PawesomeFinalEvaluation = (props) => {
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    const [ref2, inView2] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    const items = [
        {src: communityReferralfeedback, altText:"Feedback for the community referral"},
        {src: messageTabDetailFeedback, altText:"Feedback for the message tab detail"},
        {src: petSittingRequestFeedback, altText:"Feedback for choosing the new instruction"},
        {src: searchResultFeedback, altText:"Feedback for choosing the search results"},
        {src: newInstructionFeedback, altText:"Feedback for adding the new instruction"},
        {src: savedInstructionsFeedback, altText:"Feedback for the saved instruction"},
    ]

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
          <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
          >
              <div style={{padding:"10px", height: "500px", display:"flex", justifyContent:"center"}}>
                <img src={item.src} alt={item.altText} style={{height:"100%"}}/>
              </div>
            {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
          </CarouselItem>
        );
    });


    return (
        <React.Fragment>
            <div ref={ref} style={{...opacityChangeAnimation(inView)}}>
            <Row style={{
                    position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                }}>
                <Col md={{size:4, offset:1}} style={styleForColumn}>
                    <H4>
                        Evaluation
                    </H4>
                </Col>
            </Row>
            
            <Row style={{marginTop: "1.5rem", position:"relative"}}>
                <Col md={{size:10, offset:1}} style={styleForColumn}>

                    <H5>
                        Evaluate business potential as a product
                    </H5>

                    <BodyText1 style={{marginTop: "2rem"}}> 
                        We first collected feedback from <strong> experienced judges in an app design competition</strong>, many of which were pet owners surprisingly. They listened to our pitch, played with our prototypes and provided us with valuable insights in feature sets and how to potentially grow features as a business solution.  
                    </BodyText1>

                    <BodyText1 style={{marginTop: "2rem"}}> 
                        They were mostly interested in the potential power of our idea of building a social platform for pet owners. They showed us the opportunity of integrating the highly specialized activies within pet industry for example breeding into this social network. Their comments helped us <strong> consolidate the fundemental identity  </strong> of our app: community and trust. However, at the same time, their comments made us wonder how can we further <strong> polish our feature </strong> to better position this as tool for finding a short-term pet sitter, rather than merely a platform. 
                    </BodyText1>

                    <H5 style={{marginTop: "4rem"}}>
                        Usability feedback from users
                    </H5>

                    <BodyText1 style={{marginTop: "1rem", marginBottom:"2rem"}}> 
                        Aside from thinking about features, we also recruited three pet owners to do the usability testing with our developed interactive prototypes. We asked our partcipiants to perform several main user tasks: find a pet sitter, add instructions and also invited new users, and gathered feedback through both of their verbals and actions. <strong> All users successfully accomplished all of our tasks in reasonable time </strong>. This last iteration has <strong> fixed most of problems in our interaction flow</strong>. However, users still met several usability issues on certain screens during the process, denoted below.
                    </BodyText1>

                </Col>

                <Col md={1}/>
            </Row>
            </div>

            <Row style={{marginTop:"250px"}}></Row>
            <div ref={ref2} style={{...opacityChangeAnimation(inView2)}}>
            <Row>
                {/* New Row */}
                <Col md={{size: 8, offset: 2}} style={{...styleForColumn}}>
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex}/>
                            {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
                    </Carousel>
                </Col>
            </Row>
            </div>
        </React.Fragment>
    )
}