import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {PawesomeH1, Subtitle1, H4, H5, BodyTextWithLightColor, gutter, BodyText1, paddingOneSide, styleForColumn, opacityChangeAnimation} from "./pawesome_style"
import { useInView } from 'react-intersection-observer'
import ideationPhoto from "../../images/pawesome/ideation_emily_and_jj.jpg"
import storyboard1 from "../../images/pawesome/storyboardPart1.png"
import storyboard2 from "../../images/pawesome/storyboardPart2.png"
import {ZoomableImageWithModal} from "./pawesome_common_components"



export const PawesomeIdeation = (props) => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0.2,
        // root:""
    })

    const [ref2, inView2] = useInView({
        /* Optional options */
        threshold: 0.2,
        // root:""
    })


    return (
        <React.Fragment>
            <div ref={ref} style={{...opacityChangeAnimation(inView)}}>
            <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                // transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}>
                <Col md={{size:4, offset:1}} style={styleForColumn}>
                    <H4 style={{background:"white", borderRadius: "8px", position:"relative", width: "fit-content"}}>
                        Ideation
                    </H4>
                </Col>
            </Row>

            <Row style={{marginTop: "1.5rem", position: "relative"}}>
                <Col md={{size:10, offset: 1}} style={{ display:"flex", justifyContent:"flex-end", ...styleForColumn}}>
                    <BodyText1 style={{marginBottom:"2rem"}}>
                        Our schedule of development pushed us to think about potential features. The developers wanted to be able to start familiarize themselves with the technologies. 
                        Thus, I organized <strong> a brainstorm session </strong>, with not only our designer but also our engineers. Through sticky notes, each person contributed to different thoughts on features. We then consolidated those ideas into four core features, embodied in one imagined experience. 
                    </BodyText1>

                    <img src={ideationPhoto} width={"30%"} style={{alignSelf:"center", borderRadius:"8px", marginTop:"2rem"}}/> 
                </Col>
            </Row>
            </div>

            <div ref={ref2} style={{...opacityChangeAnimation(inView2)}}>
            <Row>
                <Col md={{size:10, offset: 1}} style={{ display:"flex", justifyContent:"flex-end", ...styleForColumn}}>
                    <H5 style={{marginTop:"250px"}}>
                        Our imagined user experience
                    </H5>

                    <BodyTextWithLightColor style={{marginTop:"1rem", marginBottom:"2rem"}}>
                        We thought of a mobile application that constitutes a virtual pet owner community that connects pet owners/lovers in the same neighbourhood geographically. They will share their pet experience to build the bond through <strong style={{color:"#000000"}}> a community feed </strong>. When one of the member looks for a short-term pet sitter, he or she can search for anyone who might be interested and then send <strong style={{color:"#000000"}}> a pet sitting request </strong> to them. If the request receiver agrees to help, both pet owners and sitters can <strong style={{color: "#000000"}}> add to-do items </strong> to remind the other party and communicate through a message functionality. 
                    </BodyTextWithLightColor>
                </Col>

                
                <Col md={{size: 1}}></Col>

                {/* New Row */}
                <Col md={{size: 5, offset: 1}} style={styleForColumn}>
                    <ZoomableImageWithModal image={storyboard1} header={"Storyboard Part 1"} style={{width:"100%"}}/>
                </Col>

                <Col md={{size: 5}} style={styleForColumn}>
                    <ZoomableImageWithModal image={storyboard2} header={"Storyboard Part 2"} style={{width:"100%"}}/>
                </Col>
            </Row>
            </div>
        </React.Fragment>
    )
}