import React from 'react'
import { Row, Col, Container } from 'reactstrap'

import styled from 'styled-components'
import {
  displayFont,
  displayFontWeight,
  textFont,
  textFontWeight,
} from '../site_style'

import {
  H1,
  H2,
  H3,
  H4,
  H5,
  Subtitle1,
  Subtitle1OpenSans,
  Subtitle2,
  Subtitle2OpenSans,
  BodyText1,
  BodyText2,
  Caption,
  Subtitle1OpenSansWithLightColor,
} from '../text_style'

const mainColor = '#F99558'

const PawesomeH1 = styled(H1)`
  color: ${mainColor};
  letter-spacing: -1px;
  font-size: 3.25rem;
`

const marginVertical = 50
const gutter = 44
const paddingOneSide = 22

const OriginalH4 = H4

const PawesomeH4 = ({ children, showDot = true }) => {
  return (
    <React.Fragment>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <H3>{children}</H3>
        {showDot ? (
          <div
            style={{
              width: '10px',
              height: '10px',
              marginLeft: '12px',
              borderRadius: '50%',
              backgroundColor: mainColor,
            }}
          ></div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

const BodyTextWithLightColor = styled(BodyText1)`
  line-height: 200%;
  color: #696969;
`

const BodyTextWithDoubleSpace = styled(BodyText1)`
  line-height: 200%;
`

const CaptionWithLargerFont = styled(Caption)`
  font-size: 0.9rem;
`

const styleForColumn = {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: `${paddingOneSide}px`,
  paddingRight: `${paddingOneSide}px`,
}

export const opacityChangeAnimation = inView => ({
  transition: 'opacity 0.2s ease-in',
  opacity: inView ? 1 : 0,
})
export {
  PawesomeH1,
  BodyTextWithLightColor,
  marginVertical,
  Subtitle1,
  PawesomeH4 as H4,
  OriginalH4,
  H5,
  gutter,
  BodyTextWithDoubleSpace as BodyText1,
  BodyText2,
  paddingOneSide,
  styleForColumn,
  CaptionWithLargerFont as Caption,
  Subtitle1OpenSansWithLightColor,
}
