import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import {
  Subtitle1,
  H4,
  BodyText1,
  styleForColumn,
  opacityChangeAnimation,
} from './pawesome_style'
import { useInView } from 'react-intersection-observer'

export const PawesomeLesson = props => {
  const [ref, inView] = useInView({
    threshold: 0.2,
  })

  return (
    <React.Fragment>
      <div ref={ref} style={{ ...opacityChangeAnimation(inView) }}>
        <Row>
          <Col md={{ size: 4, offset: 1 }} style={styleForColumn}>
            <H4
              style={{
                background: 'white',
                borderRadius: '8px',
                position: 'relative',
                padding: '4px',
                width: 'fit-content',
              }}
            >
              Lesson I learnt
            </H4>
          </Col>
        </Row>

        <Row style={{ marginTop: '1.5rem' }}>
          <Col md={{ size: 6, offset: 5 }} style={styleForColumn}>
            <BodyText1 style={{ marginBottom: '2rem' }}>
              This project is my first take on leading and managing a group of
              developers and designers to fully realize a minimum viable mobile
              application. What I learned mostly focuses on the area of{' '}
              <strong> product features design and team collaboration</strong>.
            </BodyText1>

            <BodyText1 style={{ marginBottom: '2rem' }}>
              From the project management side, this project made me realize{' '}
              <strong>
                {' '}
                the importance of saying no to features, and the importance of
                the courage to further scope down our problems{' '}
              </strong>
              . One of the challenge I had in making decisions for features is
              the pressure from multiple external stakeholders, mostly
              professors and users. Professors served as a business
              stakeholders, grilling us on revenue and user growth, while target
              users were making different kinds of suggestions based on their
              individual plans. Those pressure at one point made me not decisive
              enough to cut several unthoughtful features in our app, leading to
              a debt which we couldn’t res ally fill. Iteration by iteration, I
              learnt how to respond and stablize the core of our features, and
              learning that polishing out the core identity of our app with the
              help of others is much more important than filling the app with
              all features others want.
            </BodyText1>

            <BodyText1 style={{ marginBottom: '2rem' }}>
              As a designer on the team, I learnt it is important to{' '}
              <strong>
                {' '}
                communicate understanding of users to engineers to back our
                design decisions
              </strong>
              . The similar understanding of users makes it easier to convince
              engineers to collaborate and think deeper on developing features.
              When the time is right, they could even be the source of the
              creativity of user center design. That is the reason we included
              them in our user research and ideation phase, different from many
              teams with similar team set-up. They proposed the idea of having
              someone refer others to get into app community, an important
              aspect.
            </BodyText1>

            <BodyText1 style={{ marginBottom: '2rem' }}>
              I also learnt that{' '}
              <strong>
                a high-fidelity clickable prototype is essential to tell the
                story and conduct user testing
              </strong>
              . Compared to a coded interactive prototype or a wireframe
              prototype, a high-fidelity prototype built in existing prototypes
              tools are relatively reliable to get the idea across to the users
              and stakeholders. We had several instances where our wireframes
              and coding prototypes got into bugs and failed to communicate
              while clickable prototypes did a much better job.
            </BodyText1>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
