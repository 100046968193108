import React from 'react'
import {Row, Col, Container, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'

import styled from 'styled-components'
// import sectionSeperator from ""
import { getColOf3 } from '../text_style'
import {SectionSeperator, ZoomableImage, ZoomableImageWithModal, AnimatedText, AnimatedTextSpan} from "../site_common_components"


// const PawesomeSectionSeperator = styled(SectionSeperator)`
//     margin-top: 50px;
//     margin-bottom: 50px;
// `

const PawesomeSectionSeperator = props => {
    return (
      <Row className={props.className} style={{marginTop: "150px", marginBottom:"100px"}}>
        <Col md={5} />
        <Col
          md={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <SeperatorCircle />
            <SeperatorCircle />
            <SeperatorCircle /> */}
  
          <div
            style={{ height: 1, backgroundColor: '#D25709', width: '50%' }}
          ></div>
        </Col>
      </Row>
    )
  }
  

export {PawesomeSectionSeperator, ZoomableImage, ZoomableImageWithModal, AnimatedText, AnimatedTextSpan}