import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import {
  Subtitle1,
  H4,
  BodyText1,
  styleForColumn,
  Caption,
  opacityChangeAnimation
} from './pawesome_style'
import { ZoomableImageWithModal } from './pawesome_common_components'
import { useInView } from 'react-intersection-observer'
import wireframe from '../../images/pawesome/pawesome_wireframe.jpg'
import codingImage from '../../images/pawesome/coding_image.jpg'
import AppOnThePhone from '../../images/pawesome/app_on_the_phone.jpg'

import toolWeUse from '../../images/pawesome/tool-we-use.png'
import softwareIcon from '../../images/pawesome/software_icon.jpg'

export const PawesomeMVP = props => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
    // root:""
  })

  return (
    <React.Fragment>
      <div style={{...opacityChangeAnimation(inView)}} ref={ref}>
      <Row style={{marginBottom:"2rem"}}>
        <Col md={{ size: 5, offset: 1 }} style={{...styleForColumn, height:"275px"}}>
          <img
            src={codingImage} className={"img-fluid"}
            style={{width:"auto", borderRadius: '8px', height:"100%", objectFit:"cover" }}
          />
        </Col>
        <Col md={{size:4,}} style={{...styleForColumn,height:"275px"}}>
          <img
            className={"img-fluid"}
            src={AppOnThePhone}
            style={{ width:"auto", borderRadius: '8px', height:"100%", objectFit:"cover"}}
          />
        </Col>
      </Row>

      <Row>
        <Col md={{ size: 4, offset: 1 }} style={styleForColumn}>
          <H4>
            Minimum Viable Product
          </H4>
        </Col>
      </Row>

      <Row style={{ marginTop: '1.5rem', position: 'relative' }}>
        <Col md={{ size: 10, offset: 1 }} style={styleForColumn}>
          <BodyText1 style={{ marginBottom: '2rem' }}>
            We developed a minimum viable prototype on both{' '}
            <strong> iOS and Android </strong> with{' '}
            <strong>React Native</strong>. It allows pet owners and sitters to
            save their account information and use our features in realtime
            through Google Firebase. In the last month of our project, our team
            put more effort in ensuring the development of our minimum viable
            product in time. 
            <a style={{marginLeft:"4px"}} href={'https://github.com/Aslan-mu/PawsomeCompanions'}>
              Github Link
            </a>
          </BodyText1>

          <BodyText1 style={{ marginBottom: '2rem' }}>
            I led and implemented multiple features and UI elements, while our
            designer further clarified layout details and interactions in high
            fidelity prototype. To reduce the gap between our development and
            design, I convinced our team to adopted the <strong>Zeplin</strong>,
            a useful developer handoff tool which provides detailed styling
            codes and layout. I also made different suggestions to our designer
            about how to polish our interaction and also change certain designs
            based on our technical constraints.
          </BodyText1>

          <img
            src={softwareIcon}
            width={'50%'}
            style={{ borderRadius: '8px', alignSelf: 'center', marginTop:"2rem" }}/>
            
        </Col>

        <Col md={1} />

        {/* New Row */}

        {/* <Col md={{size: 10}} style={styleForColumn}>
          <BodyText1 style={{ marginBottom: '2rem' }}>
            With the hard work of our teams, we eventually developed a minimum
            viable prototype on both{' '}
            <strong> iOS and Android with React Native</strong>. It allows pet
            owners and sitters to save their account information and use our
            features in realtime through <strong> Google Firebase</strong>.
          </BodyText1>

          <img src={AppOnThePhone} style={{marginBottom:"1rem", borderRadius:"8px"}}/>

          <Caption>
            <a href={"https://github.com/Aslan-mu/PawsomeCompanions"}>Github Link</a>
          </Caption>
        </Col> */}

      </Row>
      </div>
    </React.Fragment>
  )
}
