import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {Subtitle1, H4, H5, BodyText1, styleForColumn, BodyTextWithLightColor, opacityChangeAnimation} from "./pawesome_style"
import {ZoomableImageWithModal, AnimatedTextSpan} from "./pawesome_common_components"
import InView, { useInView } from 'react-intersection-observer'
import wireframe from "../../images/pawesome/pawesome_wireframe.jpg"
import TrackVisibility from 'react-on-screen';

export const PawesomeWireframe = (props) => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0.2,
        // root:""
    })
    
    const [ref2, inView2] = useInView({
        /* Optional options */
        threshold: 0.2,
        // root:""
    })

    return (
        <React.Fragment>
        
        <div ref={ref} style={{...opacityChangeAnimation(inView)}}>
        <Row style={{
                    position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                    // transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
                }}>
            <Col md={{size:4, offset:1}} style={styleForColumn}>
                <H4 style={{background:"white", borderRadius: "8px", position:"relative", width: "fit-content"}}>
                    Wireframe
                </H4>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem", position:"relative"}}>
             <Col md={{size:10, offset:1}} style={styleForColumn}>
                <BodyText1 style={{marginBottom:"2rem"}}>
                    To convey our ideas to users for evaluation, Emily and I created a clickable wireframe to illustrate the experience we imagined. With the help from multiple existing UI patterns for our features, we quickly created screens for main user flow, connected with a standard tab navigation.  
                </BodyText1>
            </Col>

            <Col md={{size: 1}}> </Col>
            {/* New Row */}

            <Col md={{size: 10, offset: 1}} style={{display:"flex", justifyContent: "center", marginTop:"2rem"}}>
                <img className={"img-fluid"} src={wireframe} style={{width: "95%"}}>
                </img>
            </Col>
            <Col md={1}></Col>
        </Row>
        </div>
        <Row style={{marginTop:"250px"}}></Row>
        <div ref={ref2} style={{...opacityChangeAnimation(inView2)}}>
        <Row>
            {/* New Row */}
            <Col md={{size: 10, offset: 1}} style={{marginTop:"2rem", ...styleForColumn}}>
                <H5 style={{marginBottom: "1rem"}}>
                    Positive feedback from 4 pet owners evaluating our wireframe
                </H5>

                <BodyTextWithLightColor style={{marginBottom:"2rem"}}>
                    With the clickable wireframe (<a style={{color:"black", textDecoration:"underline"}} href="https://invis.io/CRU8Y0EDPAT">link</a>), we recruited 4 pet owners to understand their perspectives on the features, information architecture and interaction flow. To our surprise, 
                    all users acknowledged the importance of our features and suggest they did feel a sense of trust and community when performing some of our user tasks.

                    {/* However, they revealed that more effort needs to be done to improve the sense of “collaboration” and “trust”.  */}
                </BodyTextWithLightColor>
                
                
                <H5 style={{marginTop:"4rem", marginBottom:"1rem"}}>Need more effort on deepening that sense of “collaboration” and “trust”</H5>
                <BodyTextWithLightColor style={{marginBottom:"2rem"}}>
                    1: Some usability problems of our <strong> interaction </strong> flow reduce this feeling of “collaboration”. Most notably, our placement of shared to-do list confused almost all users. They literally couldn’t find that important feature. 
                </BodyTextWithLightColor>
                
                {/* <H5 style={{marginTop:"250px", marginBottom:"1rem"}}>Need more effort on deepening that sense of “collaboration” and “trust”</H5> */}
                <BodyTextWithLightColor style={{marginBottom:"2rem"}}>
                    2: They feel <strong> the existing details </strong> on our wireframes still are not strong enough to give them a strong sense of trust. They still don’t have much confidence pointing out who they can definitely trust, and want more measures to see the growth of the trust in this app. 
                </BodyTextWithLightColor>
            </Col>
        </Row>
        </div>
        </React.Fragment>
    )
}