import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/layout'
import SEO from '../components/seo'
import { getSeperatorWithHeight } from '../components/text_style'
import { Container, Row } from 'reactstrap'
import Footer from './footer'
import { PawesomeSectionSeperator } from '../components/pawesome/pawesome_common_components'
import { PawesomeProblemStatement } from '../components/pawesome/pawesome_problem_statement'
import { PawesomeContribution } from '../components/pawesome/pawesome_contribution'
import { PawesomeIntro } from '../components/pawesome/pawesome_intro'
import { PawesomeIdeation } from '../components/pawesome/pawesome_ideation'
import { PawesomeResearch } from '../components/pawesome/pawesome_research'
import { PawesomeWireframe } from '../components/pawesome/pawesome_wireframe'
import { PawesomePrototype } from '../components/pawesome/pawesome_prototype'
import { PawesomeMVP } from '../components/pawesome/pawesome_mvp'
import { PawesomeFinalEvaluation } from '../components/pawesome/pawesome_evaluation'
import { PawesomeLesson } from '../components/pawesome/pawesome_lesson'
import { PawesomeDesignGoal } from '../components/pawesome/pawesome_design_goal'
import Scrollspy from 'react-scrollspy'

const PawesomeCompanion = () => {
  return (
    <Layout>
      <SEO
        title={'Pawesome Companion'}
        keywords={['Mobile App Design', 'Pet', 'Junjie Xu']}
      />

      {getSeperatorWithHeight(50)}

      <Scrollspy
        items={[
          'overview',
          'problem_statement',
          'research',
          'design_goal',
          'ideation',
          'wireframe',
          'prototype',
          'mvp',
          'evaluation',
          'lesson',
        ]}
        style={{
          position: 'fixed',
          top: '150px',
          paddingTop: '48px',
          listStyleType: 'none',
        }}
        currentClassName={'is-current-bpresent pawesome'}
        scrolledPastClassName={'is-past-bpresent pawesome'}
        offset={-30}
        className={'sidebar'}
      >
        <li className={'scollliv2 pawesome'}>
          <a href="#">Overview</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#problem_statement">Problem Statement</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#research">Research</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#design_goal">Design Goal</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#ideation">Ideation</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#wireframe">Wireframe</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#prototype">Prototype</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#mvp">MVP</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#evaluation">Evaluation</a>
        </li>
        <li className={'scollliv2 pawesome'}>
          <a href="#lesson">Lesson</a>
        </li>
      </Scrollspy>

      <Container
        style={{
          marginLeft: '0px',
          marginRight: '0px',
          maxWidth: '1400px',
          alignSelf: 'center',
        }}
      >

        <section id={"overview"}>
        <PawesomeIntro />

        <PawesomeSectionSeperator />
        
        <PawesomeContribution />
        </section>
        <section id={"problem_statement"}>
        <PawesomeSectionSeperator />

        <PawesomeProblemStatement />
        </section>

        <section id={"research"}>
        <PawesomeSectionSeperator />

        <PawesomeResearch />
        </section>

        <section id={"design_goal"}>
        <PawesomeSectionSeperator />

        <PawesomeDesignGoal />
        </section>

        <section id={"ideation"}>
        <PawesomeSectionSeperator />

        <PawesomeIdeation />
        </section>

        <section id={"wireframe"}>
        <PawesomeSectionSeperator />

        <PawesomeWireframe />
        </section>
        <section id={"prototype"}>
        <PawesomeSectionSeperator />

        <PawesomePrototype />
        </section>

        <section id={"mvp"}>
        <PawesomeSectionSeperator />

        <PawesomeMVP />
        </section>

        <section id={"evaluation"}>
        <PawesomeSectionSeperator />

        <PawesomeFinalEvaluation />
        </section>

        <section id={"lesson"}>
        <PawesomeSectionSeperator />

        <PawesomeLesson />
        </section>

        {getSeperatorWithHeight(200)}
        <Row className="d-flex justify-content-center">
          <a style={{ color: `#ADADAD` }} href="#">
            Back to top
          </a>
        </Row>
        {getSeperatorWithHeight(20)}

        <Footer />
      </Container>
    </Layout>
  )
}

export default PawesomeCompanion
