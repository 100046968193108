import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {Subtitle1, H4, H5, BodyText1, styleForColumn, BodyTextWithLightColor, opacityChangeAnimation} from "./pawesome_style"
import petOwnerPersona from "../../images/pawesome/pet_owner_persona.png"
import affinityDiagram from "../../images/pawesome/pawesome-affinity-diagram.png"
import competitiveAnalysis from "../../images/pawesome/pawesome_competitive_analysis.png"

import { useInView } from 'react-intersection-observer'
import {ZoomableImage, ZoomableImageWithModal} from "./pawesome_common_components"
import { Caption, BodyText2 } from '../text_style'

export const PawesomeResearch = (props) => {
    
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0.1,
        // root:""
    })

    const [ref2, inView2] = useInView({
        /* Optional options */
        threshold: 0.1,
        // root:""
    })

    const [ref3, inView3] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })
    const [ref4, inView4] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>

            <div ref={ref} style={{...opacityChangeAnimation(inView)}}> 
            <Row style={{
                    position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                    // transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
                }}>
                <Col md={{size:4, offset:1}} style={styleForColumn}>
                    <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                        Research
                    </H4>
                </Col>
            </Row>
            

            <Row style={{marginTop: "1.5rem", position: "relative"}}>
                <Col md={{size:10, offset:1}} style={styleForColumn}>
                    <BodyText1 style={{marginBottom: "3rem"}}>
                        Our pitch to the whole class on this issue brought many attentions and many questions along with it. We realized how little we know about pet owners’ flow in ensuring the care for their pet sitting, leading us to <strong>our interview with both pet owners and sitters and analysis on multiple competitive products</strong>.
                    </BodyText1>

                    <img src={competitiveAnalysis} style={{marginBottom: "2rem", width:"60%",alignSelf:"center"}}>
                    </img>
                </Col>
            </Row>
            </div>

            <Row style={{marginTop: "1.5rem", position: "relative"}}>
                <Col md={{size:10, offset:1}} style={styleForColumn}>
                    <div style={{marginTop:"250px"}}></div>
                    <div style={{...opacityChangeAnimation(inView2)}} ref={ref2}>
                        <H5 style={{marginBottom:"1rem"}}>
                            Finding a short-term pet sitter is important
                        </H5>

                        <BodyTextWithLightColor>
                            We found out the importance of finding a pet sitter even for a short period time lies in that it reduces pet’s possibility for developing seperation anxiety, which can lead to dangerous behavior. 
                        </BodyTextWithLightColor>
                    

                    <H5 style={{marginTop:"3rem", marginBottom:"1rem"}}>
                        Pet sitters don’t often use existing apps for this task
                    </H5>

                    <BodyTextWithLightColor>
                        To our surprise, our user interview revealed that most of them do not use many popular pet sitting apps for finding a short-term pet sitter. We concluded that those apps do not fully match the users’ flow in looking for short pet sitting and pain points in finding a short-term pet sitter. 
                    </BodyTextWithLightColor>

                    </div>

                    <div style={{marginTop:"250px"}}></div>
                    <div ref={ref3} style={{...opacityChangeAnimation(inView3)}}>
                    <BodyText1>
                        <strong>Pain point 1, most of existing apps connect pet owners with strangers professionals, but pet owners told us they couldn’t trust pet owners.</strong> <BodyTextWithLightColor style={{display:'inline'}}> They prefer to find help from friends or neighbors whom they can have some communication and build trust with. </BodyTextWithLightColor> 
                    </BodyText1>
                    
                    <BodyText1 style={{marginTop:"2rem"}}>
                        <strong> Pain point 2, most existing apps lack the ability for pet owners to provide enough detailed instructions for taking care of their pets. </strong> <BodyTextWithLightColor style={{display:'inline'}}> Those instructions can be important for pet sitters to take a good care of their pets and avoid incorrect way of treating pets. Right now, their instructions were scattered in Messenger chat or paper printed.</BodyTextWithLightColor>
                    </BodyText1>

                    </div>
                    <BodyText1 style={{marginTop:"200px"}}></BodyText1>
                </Col>

                <Col md={{size:1}}/>
            </Row>
            
            <div ref={ref4} style={{...opacityChangeAnimation(inView4)}}> 
            <Row>
                {/* New Row */}
                <Col md={{size: 4, offset: 1}} style={{...styleForColumn, height:"270px", alignItems:"center"}} >
                    <ZoomableImageWithModal image={petOwnerPersona} header={"Header Persona"} style={{height:"100%", textAlign:"center"}} imgStyle={{border:"1px solid rgba(201,201,201,0.5)", borderRadius:"8px", height:"100%"}}>
                    </ZoomableImageWithModal>

                    <Caption style={{marginTop:"1rem"}}> Pet owner persona </Caption>
                </Col>

                <Col md={{size: 6}} style={{...styleForColumn, height:"270px", alignItems:"center"}}>
                    <ZoomableImageWithModal image={affinityDiagram} header={"Affinity Diagram"} style={{height:'100%', textAlign:"center"}} imgStyle={{border:"1px solid rgba(201,201,201,0.5)", borderRadius:"8px", height:"100%", }}>

                    </ZoomableImageWithModal>

                    <Caption style={{marginTop:"1rem"}}> Affinity diagram </Caption>
                </Col>

                <Col md={{size: 1}}/>

                {/* New Row */}
                {/* Need Animated Text */}
                {/* <Col md={{size: 6, offset: 5}} style={styleForColumn}>
                    <BodyText1 style={{marginBottom:"2rem"}}>
                        First, most of existing apps connect pet owners with strangers professionals, but pet owners told us they couldn’t trust pet owners. <strong> They prefer to find help from friends or neighbors they can have some communication and build trust with</strong>.
                    </BodyText1>

                    <BodyText1 style={{marginBottom:"2rem"}}>
                        Second, <strong>most of existing apps lack the ability for pet owners to provide detailed instructions for taking care of their pets</strong>. Those instructions can be important for pet sitters to take a good care of their pets and avoid incorrect way of treating pets. Right now, their instructions were scattered in Messenger chat or paper printed.
                    </BodyText1>

                    <ZoomableImageWithModal image={affinityDiagram} header={"Affinity Diagram"}>

                    </ZoomableImageWithModal>
                </Col>
                
                <Col md={{size:1}}/> */}

                {/* <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/> */}
            </Row>
            </div>
        </React.Fragment>
    )
}